<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";
import { TowerGrid } from "~/extends/tower";
import { formatNumber, round } from "@/helpers";
import copier from "clipboard-copy";
import { getGame } from "~~/extends/tower/tower.service";
import { useTowerStore } from "~~/extends/tower/tower.store";
import { storeToRefs } from "pinia";

const game = ref<any>();
const isActiveFair = ref(false);

const icon = computed(() => "tower");

const gameTotal = computed(() => ((game.value?.amount || 0) + (game.value?.profit || 0)).toFixed(2));

const { t } = useI18n();

const localePath = useLocalePath();

const fairFields = computed(() => [
  {
    title: t("modal.fair.client_seed"),
    copy: true,
    value: game.value?.seed?.seed?.client_seed,
  },
  {
    title: t("modal.fair.server_seed"),
    copy: true,
    value: game.value?.seed?.seed?.server_seed_hashed,
  },
  {
    title: t("modal.fair.server_seed_opened"),
    copy: true,
    value: game.value?.seed?.seed?.server_seed || t("modal.fair.server_seed_closed"),
  },
  {
    title: t("modal.fair.nonce_short"),
    value: game.value?.seed?.nonce,
  },
]);
const fairPath = computed(() => {
  return {
    path: "/fairness",
    query: {
      game: game.value?.name,
      clientSeed: game.value?.seed.seed.client_seed,
      serverSeed: game.value?.seed.seed.server_seed || "",
      nonce: game.value?.seed.nonce,
      mines: game.value?.state?.minesCount,
    },
  };
});

const towerStore = useTowerStore();
const { rtp: towerRtp } = storeToRefs(towerStore);

const { $axios, $showToast } = useNuxtApp();

async function onBeforeOpen(e) {
  const params = e.ref.params.value;
  isActiveFair.value = false;
  game.value = undefined;
  $axios.$get(`games/${params.id}`).then(({ response }) => {
    game.value = response;

    if (game.value.name === "tower")
      getGame().then(({ response }) => {
        towerRtp.value = response.rtp;
      });
  });
}
function toggleFair() {
  isActiveFair.value = !isActiveFair.value;
}
function onCopy(val: string) {
  copier(val);
  $showToast(t("modal.credentials.copied"), "success");
}
function onCopyLink() {
  onCopy(`${window.location.origin}/tower?game=${game.value.id}`);
}
const router = useRouter();
function onBeforeClose() {
  console.log("onBeforeClose");

  router.replace({
    query: {
      game: undefined,
    },
  });
}
</script>

<script lang="ts">
import { directive } from "vue-tippy";

export default {
  directives: {
    tippy: directive,
  },
};
</script>

<template>
  <VueFinalModal name="game" @closed="onBeforeClose" @before-open="onBeforeOpen">
    <ModalBase title="" name="game">
      <template v-if="game" #header>
        <div class="modal-fair__header">
          <div class="modal-fair__header-icon">
            <FontIcon :icon="icon" size="20" />
          </div>
          <div>
            <div class="modal-fair__header-title">
              <span>{{ game?.name }}</span>: {{ game?.id?.toLocaleString() }}
            </div>
            <div class="modal-fair__header-date">
              {{ game?.created_at ? toLocalDate(game.created_at, true, true) : "" }}
            </div>
          </div>
          <VButton @click="onCopyLink" v-tippy="{ content: $t('page.common.copy') }" color="light" size="xxs">
            <FontIcon icon="copy" size="16" />
          </VButton>
        </div>
      </template>
      <div v-if="game" class="modal-fair">
        <div class="modal-fair__player">
          {{ $t("modal.fair.player") }}:
          <span>{{ game?.user?.name }}</span>
        </div>

        <div class="modal-fair_main">
          <div class="modal-fair_main__item">
            <p>{{ $t("modal.mines_fair.size") }}</p>
            <b>{{ formatNumber(round(game?.amount || 0)) }} ₽</b>
          </div>
          <div class="modal-fair_main__item">
            <p>{{ $t("modal.mines_fair.rate") }}</p>
            <b>x{{ formatNumber(round(game?.coeff || 0)) }}</b>
          </div>
          <div class="modal-fair_main__item">
            <p>{{ $t("modal.mines_fair.result") }}</p>
            <b>{{ formatNumber(round(gameTotal)) }} ₽</b>
          </div>
        </div>

        <div v-if="game" class="modal-fair_content">
          <TowerGrid simple v-if="game.name === 'tower'" :inner-game="game" />
        </div>
      </div>
      <div v-else class="modal-fair_loading">
        <VSpinner class="w-[100px]" />
      </div>
      <template v-if="game" #offset>
        <div class="modal-fair__fair">
          <button type="button" class="modal-fair__fair-header" @click="toggleFair">
            <FontIcon icon="shield-alt" size="20" />
            <div class="modal-fair__fair-header-title">
              {{ $t("modal.fair.checker") }}
            </div>
            <VButton color="light" type="button" class="modal-fair__fair-header-toggle" :class="{ active: isActiveFair }">
              <FontIcon icon="arrow" size="16" />
            </VButton>
          </button>
          <div v-if="isActiveFair" class="modal-fair__controls">
            <VInput v-for="(field, idx) in fairFields" :key="`fair-field-${idx}`" input-class="pr-20" suffix-square
              :model-value="field.value" readonly :label="field.title" color="gray"
              :suffix-icon="field.copy ? 'copy' : undefined" :suffix-function="() => onCopy(field.value)">
            </VInput>
            <VButton v-if="game.seed?.seed?.server_seed" :to="localePath(fairPath)" class="modal-fair__submit" size="lg"
              color="orange">
              {{ $t("modal.fair.check") }}
            </VButton>
            <div v-else class="modal-fair__hidden">
              {{ $t("modal.fair.hidden") }}
            </div>
          </div>
        </div>
      </template>
    </ModalBase>
  </VueFinalModal>
</template>

<style lang="scss">
.modal-fair {
  overflow: hidden;

  &_loading {
    //flex items-center justify-center w-full aspect-square
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    aspect-ratio: 1/1;
  }

  &_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: var(--padding);

    @media screen and (orientation: landscape) and (max-width: 860px) {
      padding-top: 20px;
    }

    @media screen and (max-width: 860px) and (min-width: 760px) {
      padding-top: 20px;
    }
  }

  &_main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    width: 100%;

    &__item {
      font-weight: 500;
      padding: 12px;
      background: var(--bg_secondary__200);
      color: var(--color_secondary__100);
      text-align: center;
      border-radius: 4px;

      p {
        font-weight: 600;
      }

      b {
        font-size: 16px;
        color: var(--color_main__100);
        margin-top: 8px;
        display: block;
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    width: 100%;

    &-icon {
      width: 32px;
      height: 32px;
      border-radius: var(--b-radius-sm);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      background: radial-gradient(73.33% 73.33% at 50% 50%, #9db1ff 0%, #6d3eff 100%);
      color: #ffffff;
      margin-top: auto;
      margin-bottom: auto;
    }

    &-title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 4px;
      line-height: 17px;
      color: var(--color_main__100);

      span {
        text-transform: capitalize;
      }
    }

    &-date {
      color: #ccd2e8;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
    }

    .btn-light {
      margin-left: auto;
      margin-right: 32px;
      border-radius: 2px;
    }
  }

  &__player {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--color_secondary__100);
    margin-bottom: 20px;

    span {
      color: var(--color_main__100);
    }
  }

  &__fair {
    padding: 16px 20px;
    border-top: 1px solid var(--bg_main__300);

    &-header {
      color: var(--color_secondary__100);
      width: 100%;
      display: flex;
      align-items: center;

      &-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        margin-left: 12px;
        color: var(--primary-800);
      }

      &-toggle {
        padding: 0;
        margin-left: auto;
        border-radius: var(--b-radius-sm);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;

        &.active {
          svg {
            transform: rotate(180deg);
          }
        }

        svg {
          transition: all 0.2s;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__controls {
    margin-top: 20px;
    display: grid;
    gap: var(--padding);

    &-copy {
      height: 100%;
      aspect-ratio: 1/1;
    }
  }

  &__submit {
    width: 100%;
  }

  &__hidden {
    color: var(--color_secondary__100);
    text-align: center;
    font-weight: 600;
    padding: var(--padding) 0;
  }

  @media screen and (min-width: 760px) {
    .popit-game {
      padding: 0;

      &_main {
        width: calc((45px * 7) + (10px * 6) + (10px * 2));
      }

      &__item {
        width: 45px;
        height: 45px;
      }
    }
  }

  @media screen and (max-width: 760px) {
    .popit-game {
      padding: 0;

      &_main {
        width: calc((45px * 7) + (1.5vw * 6) + (1.5vw * 2));
      }

      &__item {
        width: 45px;
        height: 45px;
      }
    }
  }

  @media screen and (min-width: 400px) and (max-width: 500px) {
    .popit-game {
      padding: 0;

      &_main {
        width: calc((35px * 7) + (1.5vw * 6) + (1.5vw * 2));
      }

      &__item {
        width: 35px;
        height: 35px;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .popit-field {
      position: relative;
      width: 100%;
      aspect-ratio: 378/500;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .popit-game {
      padding: 0;
      margin-top: -40px;
      transform: rotate(-90deg) scale(0.93);

      &_main {
        width: 100vw;
        gap: 2vw;
        padding: 2vw;
      }

      &__item {
        width: 12vw;
        height: 12vw;
      }
    }
  }
}

@include mobile {
  .modal-fair {
    &_main {
      display: flex;
      flex-wrap: wrap;

      &__item {
        flex-grow: 1;
        padding: 8px;

        b {
          margin-top: 4px;
        }
      }
    }
  }
}
</style>
